import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { DarkModal } from '../../../styles/DarkModal.styles';
import styled from 'styled-components';

interface SearchMediaProps {
  show: boolean;
  onHide: () => void;
}

const Image = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  margin-bottom: 10px;

  &:hover {
    transform: scale(1.05);
  }
`;

const SelectedImage = styled(Image)`
  margin-bottom: 10px;
`;

const SearchMedia = ({ show, onHide }: SearchMediaProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const imageBaseUrl = 'https://image.tmdb.org/t/p/';
  const imageSize = 'w185';

  useEffect(() => {
    if (show) {
      setSearchTerm('');
      setSearchResults([]);
      setSelectedMedia(null);
      setError(null);
    }
  }, [show]);

  const handleSearch = async () => {
    if (searchTerm.trim() !== '') {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`https://api.themoviedb.org/3/search/multi`, {
          params: {
            api_key: process.env.REACT_APP_TMDB_API_KEY,
            query: searchTerm,
          },
        });
        setSearchResults(response.data.results);
      } catch (error) {
        console.error('Error fetching media data:', error);
        setError('Failed to fetch media data. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectMedia = (media: any) => {
    setSelectedMedia(media);
    const mediaTitle = media.title || media.name;
    const justWatchUrl = `https://www.justwatch.com/us/search?q=${encodeURIComponent(mediaTitle)}`;
    window.open(justWatchUrl, '_blank');
    onHide();
  };

  return (
    <DarkModal show={show} onHide={onHide} customSize="md">
      <DarkModal.Header closeButton>
        <DarkModal.Title>Search Media</DarkModal.Title>
      </DarkModal.Header>
      <DarkModal.Body>
        <Form>
          <Form.Group controlId="searchTerm">
            <Form.Label>Search for a movie or TV show</Form.Label>
            <Form.Control
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter title"
            />
            <Button variant="primary" onClick={handleSearch} className="mt-2">
              {loading ? <Spinner animation="border" size="sm" /> : 'Search'}
            </Button>
          </Form.Group>

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          {selectedMedia && (
            <div className="mt-4">
              <h5>Selected Media:</h5>
              <Row className="mt-2">
                <Col xs={12} className="d-flex justify-content-center">
                  <SelectedImage
                    src={
                      selectedMedia.poster_path
                        ? `${imageBaseUrl}${imageSize}${selectedMedia.poster_path}`
                        : '/path/to/default-image.jpg'
                    }
                    alt={selectedMedia.title || selectedMedia.name}
                  />
                </Col>
              </Row>
              <div>
                <p>{selectedMedia.overview}</p>
              </div>
            </div>
          )}

          {!selectedMedia && searchResults.length > 0 && (
            <div className="mt-4">
              <h5>Search Results:</h5>
              <Row className="mt-2" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {searchResults.map((media) => (
                  <Col key={media.id} xs={6} sm={4} md={3} lg={2} className="mb-3">
                    <Image
                      src={
                        media.poster_path
                          ? `${imageBaseUrl}${imageSize}${media.poster_path}`
                          : '/path/to/default-image.jpg'
                      }
                      alt={media.title || media.name}
                      onClick={() => handleSelectMedia(media)}
                    />
                    <p style={{ fontSize: '0.9rem' }}>
                      {media.title || media.name}
                      {media.release_date && ` (${media.release_date.split('-')[0]})`}
                    </p>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Form>
      </DarkModal.Body>
      <DarkModal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
      </DarkModal.Footer>
    </DarkModal>
  );
};

export default SearchMedia;
