import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '../../config/config';
import { Col, Form } from 'react-bootstrap';
import { 
  MediaContainer, 
  Title, 
  FilterBar, 
  StyledCard, 
  CenteredRow, 
  TypeLabel, 
  StyledButton, 
  StyledMenu, 
  StyledMenuItem, 
  MovieCount, 
  SortingGroup, 
  TypeGroup 
} from './PhysicalMovieCollection.styles';
import AddMovieModal from './components/AddMovieModal';
import EditMovieModal from './components/EditMovieModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Movie {
  id: string;
  title: string;
  year: number;
  image: string;
  type: string;
}

const PhysicalMovieCollection = () => {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [filteredMovies, setFilteredMovies] = useState<Movie[]>([]);
  const [filterType, setFilterType] = useState<string>('4K Ultra HD'); 
  const [sortOption, setSortOption] = useState<string>('title-asc');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState<Movie | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const moviesCollection = collection(db, 'PhysicalMovieCollection');
        const movieSnapshot = await getDocs(moviesCollection);
        const movieList = movieSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Movie[];

        setMovies(movieList);
        const initialFilteredMovies = movieList.filter(movie => movie.type === '4K Ultra HD');
        setFilteredMovies(initialFilteredMovies);
      } catch (error) {
        console.error("Error fetching movies: ", error);
      }
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    let sortedMovies = [...movies];
    
    if (sortOption === 'title-asc') {
      sortedMovies.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOption === 'title-desc') {
      sortedMovies.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOption === 'year-asc') {
      sortedMovies.sort((a, b) => a.year - b.year);
    } else if (sortOption === 'year-desc') {
      sortedMovies.sort((a, b) => b.year - a.year);
    }

    if (filterType === 'All') {
      setFilteredMovies(sortedMovies);
    } else {
      setFilteredMovies(sortedMovies.filter(movie => movie.type === filterType));
    }
  }, [filterType, sortOption, movies]);

  const handleAddMovie = async (newMovie: Movie) => {
    const duplicate = movies.find(
      movie =>
        movie.title.toLowerCase() === newMovie.title.toLowerCase() &&
        movie.year === newMovie.year &&
        movie.type === newMovie.type
    );

    if (duplicate) {
      toast.error(`This movie already exists in your collection in the ${newMovie.type} format.`);
      return;
    }

    try {
      const moviesCollectionRef = collection(db, 'PhysicalMovieCollection');
      const docRef = await addDoc(moviesCollectionRef, newMovie);
      const addedMovie = { ...newMovie, id: docRef.id };
      setMovies([...movies, addedMovie]);

      if (filterType === 'All' || filterType === addedMovie.type) {
        setFilteredMovies([...filteredMovies, addedMovie]);
      }
      toast.success(`Movie "${newMovie.title}" added successfully!`);
    } catch (error) {
      console.error("Error adding movie: ", error);
      toast.error("Failed to add movie.");
    }
  };

  const handleEditMovie = async (updatedMovie: Movie) => {
    try {
      const movieDocRef = doc(db, 'PhysicalMovieCollection', updatedMovie.id);
      await updateDoc(movieDocRef, updatedMovie as Partial<Movie>);
      
      const updatedMovies = movies.map(movie => (movie.id === updatedMovie.id ? updatedMovie : movie));
      setMovies(updatedMovies);
      
      if (filterType === 'All' || filterType === updatedMovie.type) {
        setFilteredMovies(updatedMovies.filter(movie => filterType === 'All' || movie.type === filterType));
      } else {
        setFilteredMovies(updatedMovies.filter(movie => movie.type === filterType));
      }
      toast.success(`Movie "${updatedMovie.title}" updated successfully!`);
    } catch (error) {
      console.error("Error updating movie: ", error);
      toast.error("Failed to update movie.");
    }
  };

  const handleDeleteMovie = async () => {
    if (selectedMovie) {
      try {
        const movieDocRef = doc(db, 'PhysicalMovieCollection', selectedMovie.id);
        await deleteDoc(movieDocRef);
        
        const remainingMovies = movies.filter(movie => movie.id !== selectedMovie.id);
        setMovies(remainingMovies);
        setFilteredMovies(remainingMovies.filter(movie => filterType === 'All' || movie.type === filterType));
        setShowDeleteModal(false);
        toast.success(`Movie "${selectedMovie.title}" deleted successfully!`);
      } catch (error) {
        console.error("Error deleting movie: ", error);
        toast.error("Failed to delete movie.");
      }
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, movie: Movie) => {
    setAnchorEl(event.currentTarget);
    setSelectedMovie(movie);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <MediaContainer>
      <ToastContainer />
      <Title>Physical Movie Collection</Title>

      <FilterBar>
        <SortingGroup>
          <Form.Label>Sort By</Form.Label>
          <Form.Control
            as="select"
            aria-label="Sort Movies"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="title-asc">Title (A-Z)</option>
            <option value="title-desc">Title (Z-A)</option>
            <option value="year-asc">Year (Ascending)</option>
            <option value="year-desc">Year (Descending)</option>
          </Form.Control>
        </SortingGroup>

        <TypeGroup>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            aria-label="Filter Movies"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Blu-Ray">Blu-Ray</option>
            <option value="4K Ultra HD">4K Ultra HD</option>
          </Form.Control>
        </TypeGroup>

        <StyledButton onClick={() => setShowAddModal(true)}>
          Add Movie
        </StyledButton>
      </FilterBar>

      <MovieCount>
        <p>
          Showing {filteredMovies.length} {filteredMovies.length === 1 ? 'movie' : 'movies'}
        </p>
      </MovieCount>

      <CenteredRow style={{ rowGap: '20px', columnGap: '20px' }}>
        {filteredMovies.map((movie) => (
          <Col 
            key={movie.id} xs={8} sm={6} md={4} lg={3} xl={2} style={{ padding: '0' }}
          >
            <StyledCard>
              <TypeLabel type={movie.type}>
                <div className="type-text">{movie.type}</div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(e) => handleMenuClick(e, movie)}
                  className="menu-icon"
                >
                  <MoreVertIcon style={{ color: 'white' }} />
                </IconButton>
              </TypeLabel>
              <StyledCard.Img variant="top" src={movie.image} alt={movie.title} />
            </StyledCard>
          </Col>
        ))}
      </CenteredRow>

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <StyledMenuItem onClick={() => {
            setShowEditModal(true);
            handleMenuClose();
          }}>
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          setShowDeleteModal(true);
          handleMenuClose();
        }}>
          Delete
        </StyledMenuItem>
      </StyledMenu>

      <AddMovieModal 
        show={showAddModal} 
        onHide={() => setShowAddModal(false)} 
        onAddMovie={handleAddMovie} 
      />

      {selectedMovie && (
        <EditMovieModal 
          show={showEditModal} 
          onHide={() => setShowEditModal(false)} 
          movie={selectedMovie} 
          onEditMovie={handleEditMovie} 
        />
      )}

      {selectedMovie && (
        <ConfirmDeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteMovie}
          movieTitle={selectedMovie.title}
        />
      )}
    </MediaContainer>
  );
};

export default PhysicalMovieCollection;
