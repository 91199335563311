import styled from 'styled-components';
import { Container, Row, Card, Form } from 'react-bootstrap';
import { palette } from '../../styles/palette';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { darken } from 'polished';

export const StyledButton = styled.button`
  padding: 6px 10px;
  color: ${palette.darkgrey};
  outline: 0 !important;
  border: 0;
  border-radius: 7px;
  cursor: pointer;
  background: ${palette.aqua}; 
  box-shadow: 3px 3px 6px 0 #0D0D0D, -2px -2px 4px 0 #1c1c1c;

  &:hover {
    color: ${palette.darkgrey};
    background: ${darken(0.2, palette.aqua)};
  }
`;

export const SortingGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  margin-right: 15px; 

  & .form-label {
    margin-right: 8px;
    margin-bottom: 0;
    color: ${palette.white}; 
  }

  & .form-control {
    flex: 1;
    max-width: 160px;
    border: 1px solid ${palette.aqua}; 
    background-color: ${palette.black};  
    color: ${palette.white};  
    padding: 6px 10px;
    border-radius: 5px;
    appearance: none;
    position: relative;
    cursor: pointer;

    &:focus {
      outline: none;  
      box-shadow: none; 
    }

    &:hover {
      background-color: ${palette.darkgrey}; 
      color: ${palette.aqua}; 
    }

    &::after {
      content: '▼';
      font-size: 12px;
      color: ${palette.aqua};
      position: absolute;
      right: 10px;
      pointer-events: none;
    }

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }
`;

export const TypeGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  margin-right: 15px; 

  & .form-label {
    margin-right: 8px; 
    margin-bottom: 0;
    color: ${palette.white}; 
  }

  & .form-control {
    flex: 1;
    max-width: 160px;
    border: 1px solid ${palette.aqua}; 
    background-color: ${palette.black};  
    color: ${palette.white};  
    padding: 6px 10px;
    border-radius: 5px;
    appearance: none;
    position: relative;
    cursor: pointer;

    &:focus {
      outline: none;  
      box-shadow: none; 
    }

    &:hover {
      background-color: ${palette.darkgrey}; 
      color: ${palette.aqua}; 
    }

    &::after {
      content: '▼';
      font-size: 12px;
      color: ${palette.aqua};
      position: absolute;
      right: 10px;
      pointer-events: none;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`;

export const FilterBar = styled.div`
  margin: 0 120px 20px 120px;
  display: flex;
  align-items: flex-end; 
  width: calc(100% - 240px); 
  max-width: calc(100% - 240px); 
  padding: 15px 25px;
  border-radius: 7px;

  background: rgba(0, 0, 0, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.9px);
  -webkit-backdrop-filter: blur(1.9px);

  box-shadow: ${palette.aqua} 1.95px 1.95px 2.6px;

  & ${StyledButton} {
    margin-left: auto; 
  }

  @media (max-width: 768px) {
    margin: 0 10px 20px 10px; 
    width: calc(100% - 20px); 
    max-width: calc(100% - 20px); 
    flex-direction: column;
    align-items: stretch;

    & ${SortingGroup}, & ${TypeGroup} {
      margin-right: 0;
      margin-bottom: 10px; /* Space between stacked items */
      flex-direction: column;
      align-items: stretch;
      
      & .form-label {
        margin-bottom: 4px; /* Adjust spacing between label and control */
      }

      & .form-control {
        max-width: 100%;
      }
    }

    & ${StyledButton} {
      margin-left: 0; 
      width: 100%;
    }
  }
`;

export const MediaContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh; 
  width: 100%;
  padding: 0 20px 20px 20px;

  background-image: linear-gradient(to bottom, #000000, #0e080d, #121119, #0e1822, #032028, #032028, #032028, #032028, #0e1822, #121119, #0e080d, #000000);

  flex-grow: 1;
  flex-shrink: 0;

  max-width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Title = styled.h1`
  margin: 20px 0;
  text-align: center;
  color: ${palette.aqua};
  width: 100%; 
`;

export const MovieCount = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
  margin-left: auto;  
  margin-right: 140px;  
  margin-bottom: 20px;

  p {
    color: #ffffff;
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0 10px 20px 10px; 
    width: calc(100% - 20px); 
    max-width: calc(100% - 20px); 
  }
`;

export const CenteredRow = styled(Row)`
  justify-content: center; 
  width: 100%;  
  flex-grow: 1; 
`;

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 220px;
  min-width: 200px; 
  margin: 0 auto;
  margin-bottom: 15px;
  border-bottom-left-radius: 7px;  
  border-bottom-right-radius: 7px; 
  overflow: hidden; 

  background: ${palette.black};  

  box-shadow: ${palette.aqua} 1.95px 1.95px 2.6px;

  & img {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 0; 
  }

  & .card-body {
    padding: 10px;
  }

  & .card-title {
    font-size: 1rem;
  }

  & .card-text {
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: none;
  }

  @media (max-width: 768px) {
    max-width: 80%; 
  }
`;

export const TypeLabel = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  font-weight: bold;

  background: ${({ type }) =>
      type === '4K Ultra HD' 
        ? `linear-gradient(145deg, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.75) 70%)`
        : type === 'Blu-Ray' 
        ? `linear-gradient(145deg, #0092c5 0%, #0092c5 30%, #005275 70%)`
        : 'gray'};

  border-top-left-radius: 7px;  
  border-top-right-radius: 7px;
  position: relative;

  & .type-text {
    flex-grow: 1;
    text-align: center;
    margin: 0;  
  }

  & .menu-icon {
    position: absolute;
    right: 0;  
    top: 50%;
    transform: translateY(-50%);
    padding: 0;  
    margin: 0; 
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${palette.black}; 
    color: ${palette.white}; 
    border: 1px solid ${palette.darkgrey}; 
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${palette.white};
  &:hover {
    background-color: ${palette.darkgrey}; 
    color: ${palette.aqua}; 
  }
`;

export const AddMovieCard = styled.div`
  width: 100%;
  max-width: 150px; 
  margin: 10px auto;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px; 
  }

  @media (max-width: 768px) {
    max-width: 100px; 
  }

  @media (max-width: 480px) {
    max-width: 80px; 
  }
`;
