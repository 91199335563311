import React from 'react';
import { Button } from 'react-bootstrap';
import { DarkModal } from '../../../styles/DarkModal.styles';

interface ConfirmDeleteModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  movieTitle: string;
}

const ConfirmDeleteModal = ({ show, onHide, onConfirm, movieTitle }: ConfirmDeleteModalProps) => {
  return (
    <DarkModal show={show} onHide={onHide} customSize="md">
      <DarkModal.Header closeButton>
        <DarkModal.Title>Confirm Deletion</DarkModal.Title>
      </DarkModal.Header>
      <DarkModal.Body>
        Are you sure you want to delete the movie <strong>{movieTitle}</strong>?
      </DarkModal.Body>
      <DarkModal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Delete
        </Button>
      </DarkModal.Footer>
    </DarkModal>
  );
};

export default ConfirmDeleteModal;
