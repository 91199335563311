import styled from 'styled-components';
import { palette } from '../../styles/palette';

export const HubContainer = styled.div `
    height: 100vh;
    overflow: auto;

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const ContentCard = styled.div `
    margin: 10px 0;
    padding: 20px;
    border-radius: 25px;
    background: #1c1c1c;  
    border: none;

    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(0, 0, 0, 0.16);

    box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
    -webkit-box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
    -moz-box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
`;

export const ContentTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: ${palette.aqua};
`;

export const Button = styled.button`
    padding: .4rem .8rem;
    color: ${palette.aqua};
    outline: 0 !important;
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    background: linear-gradient(to top left, ${palette.black}, ${palette.darkgrey}); 
    box-shadow: 3px 3px 6px 0 #0D0D0D, -2px -2px 4px 0 #1c1c1c;

    &:hover {
        color: ${palette.aqua};
        background: linear-gradient(to top left, ${palette.black}, ${palette.grey});   
    }
`; 

export const SearchButton = styled(Button)`
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 5px;
`;