export enum ROUTES {
    home = "/",
    login = "/login",
    signIn = "/signin",
    hub = "/hub",
    physicalMovieCollection = "/physical-movie-collection"
  }
  
  export const WEEKDAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];