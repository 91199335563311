import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ROUTES } from '../../../constants';
import { palette } from '../../../styles/palette'; 
import { getAuth, signOut } from 'firebase/auth';

// Styled Components
const HeaderNavbar = styled(Navbar)`
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 #0d0d0d, -4px -4px 8px 0 #1c1c1c;
`;

const BrandLink = styled(Navbar.Brand)`
  font-family: 'Indie Flower', cursive;
  font-size: 1.8rem;
  color: ${palette.aqua};
  text-decoration: none;

  &:hover {
    color: ${palette.aqua};  
    text-decoration: none;   
  }
`;

const CustomNavbarToggle = styled(Navbar.Toggle)`
  padding: 0.4rem 0.8rem;
  color: ${palette.aqua};
  outline: 0 !important;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  background: linear-gradient(to top left, ${palette.black}, ${palette.darkgrey});
  box-shadow: 3px 3px 6px 0 #0d0d0d, -2px -2px 4px 0 #1c1c1c;

  &:hover {
    background: linear-gradient(to bottom right, ${palette.black}, ${palette.darkgrey});
  }
`;

const CustomNavLink = styled(Nav.Link)`
  padding-left: 10px;
  color: ${palette.white};
  text-decoration: none;

  &:hover {
    color: ${palette.teal};
  }
`;

const Header = () => {

  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate(ROUTES.login);
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

    return (
      <HeaderNavbar collapseOnSelect expand="lg" variant="dark">
        <Container>
          <BrandLink as={Link} to={ROUTES.home}>
            craig's hub
          </BrandLink>
          <CustomNavbarToggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              <CustomNavLink as={Link} onClick={handleSignOut}>
                Sign Out
              </CustomNavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </HeaderNavbar>
    );
  };

export default Header;