import MyNavbar from './components/MyNavbar';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeatureSection';
import Footer from './components/Footer';

const Home = () => {
  return (
    <div className="home-container">
      <MyNavbar />
      <HeroSection />
      <FeaturesSection />
      <Footer />
    </div>
  );
};

export default Home;
