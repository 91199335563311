import { Container, Row, Col, Button } from 'react-bootstrap';

const HeroSection = () => {
  return (
    <div className="bg-light text-center py-5">
      <Container>
        <Row>
          <Col>
            <h1>Welcome to My Website</h1>
            <p className="lead">
              This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.
            </p>
            <Button variant="primary" size="lg">Learn More</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
