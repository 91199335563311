import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { DarkModal } from '../../../styles/DarkModal.styles';

interface EditMovieModalProps {
  show: boolean;
  onHide: () => void;
  onEditMovie: (movie: any) => void;
  movie: {
    id: string;
    title: string;
    year: number;
    image: string;
    type: string;
  };
}

const EditMovieModal = ({ show, onHide, onEditMovie, movie }: EditMovieModalProps) => {
  const [title, setTitle] = useState(movie.title);
  const [year, setYear] = useState(movie.year);
  const [type, setType] = useState(movie.type);
  const [image, setImage] = useState(movie.image);

  useEffect(() => {
    if (show) {
      setTitle(movie.title);
      setYear(movie.year);
      setType(movie.type);
      setImage(movie.image);
    }
  }, [show, movie]);

  const handleSaveChanges = () => {
    const updatedMovie = {
      id: movie.id,
      title,
      year,
      type,
      image,
    };
    onEditMovie(updatedMovie);
    onHide(); 
  };

  return (
    <DarkModal show={show} onHide={onHide} customSize="md">
      <DarkModal.Header closeButton>
        <DarkModal.Title>Edit Movie</DarkModal.Title>
      </DarkModal.Header>
      <DarkModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control 
              type="text" 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control 
              type="number" 
              value={year} 
              onChange={(e) => setYear(Number(e.target.value))} 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control 
              as="select" 
              value={type} 
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Blu-Ray">Blu-Ray</option>
              <option value="4K Ultra HD">4K Ultra HD</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Image URL</Form.Label>
            <Form.Control 
              type="text" 
              value={image} 
              onChange={(e) => setImage(e.target.value)} 
            />
          </Form.Group>
        </Form>
      </DarkModal.Body>
      <DarkModal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </DarkModal.Footer>
    </DarkModal>
  );
};

export default EditMovieModal;
