import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Navbar } from "react-bootstrap";
import { LoginButton, LoginContainer, LoginContentCard } from "./Login.styles";

const Login: React.FC = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [authenticating, setAuthenticating] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const signIn = () => {
    setAuthenticating(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/hub");
      })
      .catch((error) => {
        setAuthenticating(false);
        setError(error.message);
      });
  };

  return (
    <LoginContainer>
      <Container>
        <Row className="justify-content-md-center align-items-center viewport-height">
          <Col xs={12} md={5} lg={4}>
            <LoginContentCard>
              <Navbar.Brand id="hub-brand">craig's hub</Navbar.Brand>
              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter email"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
              </Form.Group>
              {error && <p>{error}</p>}
              <LoginButton disabled={authenticating} onClick={signIn}>
                Login
              </LoginButton>
            </LoginContentCard>
          </Col>
        </Row>
      </Container>
    </LoginContainer>
  );
};

export default Login;
