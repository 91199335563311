import styled from 'styled-components';
import { palette } from '../../../styles/palette';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';

const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    flex: 1 1 calc(50% - 10px);
    padding: 20px;
    font-size: 18px;
  }
`;

const CollectionButton = styled.button`
  flex: 1 1 calc(50% - 10px);
  display: flex;               /* Make the button a flex container */
  align-items: center;         /* Center content vertically */
  justify-content: center;     /* Center content horizontally */
  padding: 20px;
  font-size: 16px;
  background: linear-gradient(to top left, ${palette.black}, ${palette.darkgrey}); 
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;  /* Remove underline */
  font-family: inherit;   /* Ensure it inherits the intended font */
  
  &:hover {
    color: ${palette.aqua};
    background: linear-gradient(to top left, ${palette.black}, ${palette.grey}); 
    text-decoration: none;  /* Remove underline on hover */
  }

  &:focus, &:active {
    outline: none;
    box-shadow: none;
  }
`;

const ButtonGroup = () => {
  return (
    <ButtonGrid>
      <CollectionButton as={Link} to={ROUTES.physicalMovieCollection}>My Physical Movies</CollectionButton>
      <CollectionButton as={Link} to={''}>My Physical Games (Coming)</CollectionButton>
    </ButtonGrid>
  );
};

export default ButtonGroup;
