import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { palette } from './palette';

interface DarkModalProps {
  customSize?: 'sm' | 'md' | 'lg' | 'xl';
}

export const DarkModal = styled(Modal)<DarkModalProps>`
  .modal-dialog {
    margin-top: 100px; /* Set margin from the top */
    margin-left: auto;
    margin-right: auto;
    ${({ customSize }) => {
      switch (customSize) {
        case 'sm':
          return css`
            max-width: 300px;
          `;
        case 'md':
          return css`
            max-width: 500px;
          `;
        case 'lg':
          return css`
            max-width: 800px;
          `;
        case 'xl':
          return css`
            max-width: 1140px;
          `;
        default:
          return css`
            max-width: 500px;
          `;
      }
    }}
  }

  .modal-content {
    background-color: ${palette.black}; 
    color: ${palette.white}; 
    border-radius: 10px;
    border: 1px solid ${palette.darkgrey};

    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(0, 0, 0, 0.16);

    .modal-header, .modal-footer {
      border: none; 
    }

    .modal-title {
      color: ${palette.aqua}; 
    }

    .form-control {
      border: 1px solid ${palette.aqua};
      background-color: ${palette.black}; 
      color: ${palette.white}; 
      border-radius: 4px;
      padding: 6px 12px;
      margin-bottom: 20px;
      
      &:focus {
        border-color: ${palette.aqua};
        box-shadow: none; 
        outline: none; 
      }
    }

    .btn-secondary {
      background-color: ${palette.darkgrey}; 
      color: ${palette.white}; 
      border: none;

      &:hover {
        background-color: ${palette.darkgrey}; 
        color: ${palette.aqua}; 
      }
    }

    .btn-primary {
      background-color: ${palette.aqua}; 
      color: ${palette.darkgrey}; 
      border: none;

      &:hover {
        background-color: ${palette.darkgrey}; 
        color: ${palette.aqua}; 
      }
    }

    .modal-backdrop.show {
      background-color: rgba(0, 0, 0, 0.8); 
    }

    /* Targeting the close button */
    .btn-close {
      color: ${palette.aqua};
      opacity: 1;

      width: 1.5rem; /* Adjust width */
      height: 1.5rem; /* Adjust height */

      &::before {
        content: '×'; /* Unicode for the 'x' character */
        font-size: 1.5rem; /* Adjust the size of the 'x' */
        color: ${palette.aqua}; /* Set the color for the 'x' */
      }

      &:hover::before {
        color: ${palette.white}; /* Hover color for the 'x' */
      }

      & > svg {
        fill: ${palette.aqua} !important; /* Ensure the svg icon is colored */
        width: 1.5rem; /* Adjust the width of the svg */
        height: 1.5rem; /* Adjust the height of the svg */
      }

      &:hover > svg {
        fill: ${palette.white} !important; /* Changes color on hover */
      }
    }
  }
`;
