import Header from './components/Header';
import Links from './components/Links';
import ButtonGroup from'./components/ButtonGroup';
import SearchMedia from './components/SearchMedia';
import { HubContainer, ContentCard, SearchButton, ContentTitle } from './Hub.styles';
import { Container, Row, Col } from "react-bootstrap";
import Weather from './components/Weather';
import { useState } from 'react';
import ParentsGuideMedia from './components/ParentsGuideMedia';

const Hub = () => {
  const [showSearchMedia, setShowSearchMedia] = useState(false);
  const [showParentsGuideMedia, setShowParentsGuideMedia] = useState(false);

  return (
    <HubContainer>
      <Header />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5} lg={4}>
            <ContentCard>
              <Links />
            </ContentCard>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <ContentCard>
              <Weather />
            </ContentCard>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <ContentCard>
              <ContentTitle>Media</ContentTitle>
              <ButtonGroup />

              <SearchButton onClick={() => setShowSearchMedia(true)}>
                Where to Stream
              </SearchButton>
              <SearchMedia
                show={showSearchMedia}
                onHide={() => setShowSearchMedia(false)}
              />

              <SearchButton onClick={() => setShowParentsGuideMedia(true)}>
                Parents Guide
              </SearchButton>
              <ParentsGuideMedia
                show={showParentsGuideMedia}
                onHide={() => setShowParentsGuideMedia(false)}
              />
            </ContentCard>
          </Col>
        </Row>
      </Container>
    </HubContainer>
  );
};

export default Hub;