import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const config = {
    firebaseConfig: {
      apiKey: "AIzaSyBihvdVZIz38gzVdk0wozdPOkKZIMdOblI",
      authDomain: "craig-space-react.firebaseapp.com",
      projectId: "craig-space-react",
      storageBucket: "craig-space-react.appspot.com",
      messagingSenderId: "1081063147968",
      appId: "1:1081063147968:web:1381d63ee7d5fbc8e42025",
      measurementId: "G-7HGRPK8LP3"
      }
};

// Initialize Firebase
const app = initializeApp(config.firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };