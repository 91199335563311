// @ts-ignore'
import ReactWeather, { useOpenWeather } from 'react-open-weather';

const Weather = () => {
    const { data, isLoading, errorMessage } = useOpenWeather({
      key: '6975fdb82d40df62ac721deb124ea2e5',
      lat: '52.8408',
      lon: '-6.9261',
      lang: 'en',
      unit: 'metric', // values are (metric, standard, imperial)
    });

    const weatherStyle = {
        fontFamily:  'Nunito, sans-serif',
        gradientStart:  '#000000',
        gradientMid:  '#1c1c1c',
        gradientEnd:  '#242424',
        locationFontColor:  '#42E8DF',
        todayTempFontColor:  '#42E8DF',
        todayDateFontColor:  '#fff',
        todayRangeFontColor:  '#fff',
        todayDescFontColor:  '#fff',
        todayInfoFontColor:  '#fff',
        todayIconColor:  '#42E8DF',
        forecastBackgroundColor:  'transparent',
        forecastSeparatorColor:  'transparent',
        forecastDateColor:  '#fff',
        forecastDescColor:  '#fff',
        forecastRangeColor:  '#fff',
        forecastIconColor:  '#42E8DF'
    };

    return (
      <ReactWeather
        theme={weatherStyle}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel="Carlow, IE"
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        showForecast={true}
      />
    );
  };

  export default Weather;