import {createGlobalStyle} from 'styled-components';
import {palette} from './palette';

const GlobalStyle = createGlobalStyle`
html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset {
  margin: 0;
  padding: 0;
}

img,
fieldset {
  border: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;  
  padding: 0;   
  background: black;
  font-family: 'Nunito', sans-serif;
}

/* Forms */
.form-group {
  margin-top: 20px;
}

.form-label {
  color: ${palette.aqua};
  font-family: 'Nunito', sans-serif;
}

.form-control {
  font-family: 'Nunito', sans-serif;
  background-color: ${palette.darkgrey};
  color: ${palette.white};
}



.weather-location {
    color: #42E8DF;
    font-family: 'Nunito, sans-serif';
}

.weather-today-temp {
    color: #42E8DF;
}

.weather-today-date {
    color: #fff;
}

.weather-today-range {
    color: #fff;
}

.weather-today-desc {
    color: #fff;
}

.weather-today-info {
    color: #fff;
}

.weather-today-icon {
    color: #42E8DF;
}

.weather-forecast {
    background-color: transparent;
}

.weather-forecast-separator {
    background-color: transparent;
}

.weather-forecast-date {
    color: #fff;
}

.weather-forecast-desc {
    color: #fff;
}

.weather-forecast-range {
    color: #fff;
}

.weather-forecast-icon {
    color: #42E8DF;
}

`

export default GlobalStyle;