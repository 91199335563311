import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Home from './containers/Home/Home';
import Hub from './containers/Hub/Hub';
import PhysicalMovieCollection from './containers/PhysicalMovieCollection/PhysicalMovieCollection';
import AuthRoute from './components/AuthRoute';
import Login from './components/Login';
import { ROUTES } from './constants';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, () => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) return <Spinner animation="border" variant="primary" />;

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route
          path={ROUTES.hub}
          element={
            <AuthRoute>
              <Hub />
            </AuthRoute>
          }
        />
        <Route
          path={ROUTES.physicalMovieCollection}
          element={
            <AuthRoute>
              <PhysicalMovieCollection />
            </AuthRoute>
          }
        />
        <Route path={ROUTES.login} element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
