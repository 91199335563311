import styled from "styled-components";
import { palette } from "../styles/palette"; // Adjust the import path if necessary

export const LoginContainer = styled.div`
  height: 100vh;
  padding-left: 45px;
  padding-right: 45px;
  overflow: auto;
  background: ${palette.darkgrey};
  background: -webkit-linear-gradient(
    to bottom right,
    ${palette.black},
    ${palette.darkgrey}
  );
  background: linear-gradient(
    to bottom right,
    ${palette.black},
    ${palette.darkgrey}
  );

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .viewport-height {
    height: 100vh;
  }
`;

export const LoginContentCard = styled.div`
  margin: 10px 0;
  padding: 30px;
  border-radius: 25px;
  background: #1c1c1c;
  border: none;
  background: -webkit-linear-gradient(
    to bottom right,
    ${palette.black},
    ${palette.darkgrey}
  );
  background: linear-gradient(
    to bottom right,
    ${palette.black},
    ${palette.darkgrey}
  );
  box-shadow: 6px 6px 10px 0 #0d0d0d, -4px -4px 8px 0 #1c1c1c;
`;

export const LoginButton = styled.button`
  padding: 0.4rem 0.8rem;
  margin-top: 60px;
  width: 100%;
  color: ${palette.darkgrey};
  outline: 0 !important;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bolder;
  background: linear-gradient(to top left, ${palette.aqua}, ${palette.teal});
  box-shadow: 3px 3px 6px 0 #0d0d0d, -2px -2px 4px 0 #1c1c1c;

  :hover {
    color: ${palette.darkgrey};
    background: linear-gradient(
      to bottom left,
      ${palette.teal},
      ${palette.aqua}
    );
  }
`;
